<template>
    <div class="container-fluid">
        <div class="row">
            <div class="card">
                <div class="card-header d-flex justify-content-between">
                    <div class="header-title">
                        <h4 class="card-title">Novo fornecedor</h4>
                    </div>
                </div>
                <div class="card-body">
                    <div class="new-user-info">
                        <form @submit.prevent="onSubmit()">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="fname">Data:</label>
                                    <input 
                                        type="date" 
                                        v-model="date" 
                                        class="form-control"
                                        :class="`form-control ${$v.date.$error ? 'is-invalid' : ''}`" 
                                        id="date" 
                                        placeholder="Data"
                                        @input="$v.date.$touch()" 
                                        @blur="$v.date.$touch()"
                                    >
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="phoneNumber">Entidade:</label>
                                    <input 
                                        type="text" 
                                        v-model="entity" 
                                        class="form-control"
                                        :class="`form-control ${$v.entity.$error ? 'is-invalid' : ''}`" 
                                        id="entity" 
                                        placeholder="Entidade"
                                        @input="$v.entity.$touch()" 
                                        @blur="$v.entity.$touch()"
                                    >
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="value">Valor:</label>
                                    <input 
                                        type="text" 
                                        v-model="value" 
                                        class="form-control"
                                        :class="`form-control ${$v.value.$error ? 'is-invalid' : ''}`" 
                                        id="value" 
                                        placeholder="Valor"
                                        @input="$v.value.$touch()" 
                                        @blur="$v.value.$touch()"
                                    >
                                </div>                          
                                <div class="form-group col-md-6">
                                    <label for="iban">Iban:</label>
                                    <input 
                                        type="text" 
                                        v-model="iban" 
                                        class="form-control"
                                        :class="`form-control ${$v.iban.$error ? 'is-invalid' : ''}`" 
                                        id="iban" 
                                        placeholder="Iban"
                                        @input="$v.iban.$touch()" 
                                        @blur="$v.iban.$touch()"
                                    >
                                </div>                          
                                <div class="form-group col-md-6">
                                    <label for="reference">Referência:</label>
                                    <input 
                                        type="text" 
                                        v-model="reference" 
                                        class="form-control"
                                        :class="`form-control ${$v.reference.$error ? 'is-invalid' : ''}`" 
                                        id="reference" 
                                        placeholder="Referência"
                                        @input="$v.reference.$touch()" 
                                        @blur="$v.reference.$touch()"
                                    >
                                </div>                          
                                <div class="form-group col-md-6">
                                    <label for="state">Estado:</label>
                                    <input 
                                        type="text" 
                                        v-model="state" 
                                        class="form-control"
                                        :class="`form-control ${$v.state.$error ? 'is-invalid' : ''}`" 
                                        id="state" 
                                        placeholder="Estado"
                                        @input="$v.state.$touch()" 
                                        @blur="$v.state.$touch()"
                                    >
                                </div>                          
                                <div class="form-group col-md-6">
                                    <label for="invoice">Fatura:</label>
                                    <input 
                                        type="text" 
                                        v-model="invoice" 
                                        class="form-control"
                                        :class="`form-control ${$v.invoice.$error ? 'is-invalid' : ''}`" 
                                        id="invoice" 
                                        placeholder="Fatura"
                                        @input="$v.invoice.$touch()" 
                                        @blur="$v.invoice.$touch()"
                                    >
                                </div>                          
                                <div class="form-group col-md-6">
                                    <label for="payment">Pagamento:</label>
                                    <input 
                                        type="text" 
                                        v-model="payment" 
                                        class="form-control"
                                        :class="`form-control ${$v.payment.$error ? 'is-invalid' : ''}`" 
                                        id="payment" 
                                        placeholder="Pagamento"
                                        @input="$v.payment.$touch()" 
                                        @blur="$v.payment.$touch()"
                                    >
                                </div>                                               
                            </div>
                            <hr>
                            <button type="submit" class="btn btn-primary">Adicionar</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
     </div>
</template>
<script>
    import { validationMixin } from 'vuelidate'
    import { required } from 'vuelidate/lib/validators'
export default {
   name:'UserAdd',
   mixins: [validationMixin],
   validations: {
        date: { required },
        entity:{ required  },
        value: { required },
        iban: { required },
        reference: { required },
        state: { required },
        invoice: { required },
        payment: { required },
    },
    data() {
        return {
            date: '',
            entity: '',
            value: '',
            iban: '',
            reference: '',
            state: '',
            invoice: '',
            payment: ''
        }
    },
   methods:{
        onSubmit()
        {
            let payments = JSON.parse(localStorage.getItem('payments'));
            this.$v.$touch()
            if (!this.$v.$invalid) {
                payments.push({Data: this.date, Entidade: this.entity, Valor: this.value, IBAN: this.iban, Referência: this.reference, Estado: this.state, Fatura: this.invoice, Pagamento: this.payment})
                localStorage.setItem('payments', JSON.stringify(payments));
                this.$router.push({name:'app.payments-list'})
            }
        }
    }
}
</script>